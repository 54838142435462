@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-brand-200);
}

a.active {
  background: rgba(0, 0, 0, 0.4);
  color: white;
}